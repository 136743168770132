import styled from 'styled-components';
import { colors } from '../../styles/color';

interface SectionProps {
	marginTop?: number;
}

export const Section = styled.section<SectionProps>`
    margin-top: ${props => props.marginTop ?? 0}px;

  p {
    display: flex;
    gap: 8px;
    color: ${colors.neutral['900']};
    font-size: 14px;
    line-height: 150%; /* 21px */
    font-family: Inter;
    
    &.nested {
      margin-left: 24px;
      // border 제거
    
      // 로마 숫자 리스트
      &.roman {
        margin-left: 48px;
      }
    }
  }
`;

export const MainSection = styled.div`
	color: ${colors.neutral['900']};
	font-size: 28px;
	font-style: normal;
	font-weight: 700;
	line-height: 120%; /* 33.6px */
`;

export const SubSection = styled.div`
	color: ${colors.neutral['900']};

	/* Body1/Bold */
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 10px;
  
`;

export const StyledLink = styled.a`
  color: ${colors.primary['1000']};
  text-decoration: underline;
`;