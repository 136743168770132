import React from 'react';
import styled from 'styled-components';
import { colors } from '../../styles/color';

const DownloadButton = styled.button`
	display: flex;
	align-items: center;
	font-size: 12px;
	gap: 4px;
	
	&:hover {
		color: ${colors.neutral['500']};	
	}	
`;

interface TermDownloadProps {
  onDownload: () => void;
}

const TermDownload = ({ onDownload }: TermDownloadProps) => {
  return (
    <DownloadButton onClick={onDownload}>
      <img src={'svg/download.svg'} alt="download" />
      Download
    </DownloadButton>
  );
};

export default TermDownload;