import React from 'react';
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";

const ModalWrapper = styled.div`
	width: 412px;
	background: #fff;
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 12px;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
	text-align: center;
`;

const IconContainer = styled.div`
	border-radius: 50%;
	width: 42px;
	height: 42px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 24px;
	margin-bottom: 8px;
	img {
		border-radius: 50%;
		width: 34px;
		height: 34px;
	}
`;

const Title = styled.h2`
	margin-bottom: 24px;
	font-size: 20px;
	font-weight: 600;
	color: #252525;
`;

const Message = styled.p`
	margin-bottom: 24px;
	font-size: 16px;
	color: #444444;
`;

const BottomMessage = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 4px;
	margin-bottom: 40px;
	width: 100%;
`;

const ContactText = styled.div`
	font-size: 16px;
	color: #444444;
`;

const EmailText = styled.div`
	margin-top: 8px;
	font-size: 16px;
	color: #444444;
	text-decoration: underline;
	font-weight: bold;
`;

const CloseButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-bottom: 10px;
	width: 100%;

	&:hover {
		cursor: pointer;
	}
`;

interface ClosedPermissionApprovedModalsProps {
	onClose: () => void;
}

const ClosedPermissionApprovedModals: React.FC<ClosedPermissionApprovedModalsProps> = ({
	                                                                                       onClose
                                                                                       }) => {
	return (
		<ModalWrapper>
			<CloseButtonContainer onClick={onClose}>
				<CloseIcon style={{ width: "24px", height: "24px" }}
				           sx={{
										stroke: "#ffffff",
					           strokeWidth: 1
				           }}
				/>
			</CloseButtonContainer>
			<IconContainer>
				<img src={"/svg/check_blue.svg"} className={"icon"} alt={"profiling"}/>
			</IconContainer>
			<Title>Permission Request Approved</Title>
			<Message>Your permission request has been approved.</Message>
			<BottomMessage>
				<ContactText>
					If you have any questions, please contact us at
				</ContactText>
				<EmailText>
					support@aligo.ai
				</EmailText>
			</BottomMessage>
		</ModalWrapper>
	);
};

export default ClosedPermissionApprovedModals;
