import React from "react";
import Section from "../layout/Section";
import { SectionTitle } from "./Celeb";
import { CelebData } from "../interfaces/celeb.interfaces";
import InstagramReport from "./InstagramReport";
import styled from "styled-components";
import SearchTrendReport from "./SearchTrendReport";
import {colors} from "../../styles/color";

export const ReportTitle = styled.h4`
  ${({theme}) => theme.font.title1};
  ${({theme}) => theme.font.regular};
  max-width: 1300px;
  margin: auto;
  width: 100%;
`;

export const CardTitle = styled.span`
  ${({ theme }) => theme.font.body1};
  ${({ theme }) => theme.font.regular};
  display: flex;;
  align-items: center;
  gap: 0.4rem;
  color: ${colors.neutral["900"]};
`;

export const CardSubtitle = styled.div`
  margin-top: 0.8rem;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  color: ${colors.neutral["500"]};
`;

export const GridCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 2.4rem 2rem;
  position: relative;
  border: 0.1rem solid ${colors.neutral["0"]};
  border-radius: 8px;

  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15),
  0px 2px 5px rgba(0, 0, 0, 0.1);

  transition: box-shadow 0.2s ease-in-out;
  &:hover {
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2),
    0px 3px 8px rgba(0, 0, 0, 0.15);
  }
`;

interface EvaluationPoint {
  $backgroundColor?: string;
}

export const CardData = styled.div<EvaluationPoint>`
  ${({ theme }) => theme.font.h3};
  ${({ theme }) => theme.font.bold};
  line-height: 100%;
  word-break: break-all;
  word-wrap: break-word;
  text-align: right;
  
  .evaluation,
  .sub-text {
    display: block;
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.regular};
    color: ${colors.neutral["500"]};
  }
  .evaluation::before {
    display: inline-block;
    content: "";
    width: 1rem;
    height: 1rem;
    border-radius: 1rem;
    margin-right: 0.8rem;
    background-color: ${(props) => props.$backgroundColor || "var(--gray-500)"};
  }
`;

export const CountryColorSet = [
  "#4DB6AC",
  "#3F51B5",
  "#FFA000",
  "#F06292",
  "#8D6E63",
  "#851DF0",
];

interface ReportSectionProps {
  celebData: CelebData;
}

const ReportSection = ({ celebData }: ReportSectionProps) => {
  const instagramData = celebData?.instagram;
  const searchTrendData = celebData?.searchTrend;

  const showReportsTitle =
    instagramData?.instagramName || (searchTrendData?.topSearchPercent?.length > 0);

  return (
    <Section mt={12}>
      {showReportsTitle && <SectionTitle>Reports</SectionTitle>}

      {instagramData?.instagramName && (
        <InstagramReport celebData={celebData} />
      )}

      {searchTrendData?.topSearchPercent?.length > 0 && (
          <SearchTrendReport celebData={celebData} />
      )}
    </Section>
  );
};

export default ReportSection;
