import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import React, { useEffect, useState, useCallback } from "react";
import { putLike } from "../../api/like";

const defaultColor = {
  true: "var(--crimson-600)",
  false: "var(--gray-600)",
};

interface FavoriteColorProps {
  true: string;
  false: string;
}

interface FavoriteIconProps {
  size?: number;
  color?: FavoriteColorProps;
  status?: boolean;
}

export const FavoriteIcon = ({
                               size = 48,
                               color = defaultColor,
                               status = false,
                             }: FavoriteIconProps) => {
  const path = status
    ? "m480-173.85-30.31-27.38q-97.92-89.46-162-153.15-64.07-63.7-101.15-112.35-37.08-48.65-51.81-88.04Q120-594.15 120-634q0-76.31 51.85-128.15Q223.69-814 300-814q52.77 0 99 27t81 78.54Q514.77-760 561-787q46.23-27 99-27 76.31 0 128.15 51.85Q840-710.31 840-634q0 39.85-14.73 79.23-14.73 39.39-51.81 88.04-37.08 48.65-100.77 112.35Q609-290.69 510.31-201.23L480-173.85Z"
    : "m480-173.85-30.31-27.38q-97.92-89.46-162-153.15-64.07-63.7-101.15-112.35-37.08-48.65-51.81-88.04Q120-594.15 120-634q0-76.31 51.85-128.15Q223.69-814 300-814q52.77 0 99 27t81 78.54Q514.77-760 561-787q46.23-27 99-27 76.31 0 128.15 51.85Q840-710.31 840-634q0 39.85-14.73 79.23-14.73 39.39-51.81 88.04-37.08 48.65-100.77 112.35Q609-290.69 510.31-201.23L480-173.85Zm0-54.15q96-86.77 158-148.65 62-61.89 98-107.39t50-80.61q14-35.12 14-69.35 0-60-40-100t-100-40q-47.77 0-88.15 27.27-40.39 27.27-72.31 82.11h-39.08q-32.69-55.61-72.69-82.5Q347.77-774 300-774q-59.23 0-99.62 40Q160-694 160-634q0 34.23 14 69.35 14 35.11 50 80.61t98 107q62 61.5 158 149.04Zm0-273Z";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      viewBox="0 -960 960 960"
      fill={status ? color.true : color.false}
    >
      <path d={path} />
    </svg>
  );
};

// FavoriteButton.tsx의 타입 정의 부분
interface FavoriteButtonProps {
  id: string;
  size?: number;
  color?: FavoriteColorProps;
  status?: boolean;
  onChange?: (id: string, newStatus: boolean) => void;
}

interface LikeResponse {
  ok: boolean;
  statusCode: number;
  message: string;
  data: boolean;
}

interface LikeErrorResponse {
  ok: boolean;
  statusCode: number;
  data: string;
}

const FavoriteButton = ({
                          id,
                          size = 48,
                          color = defaultColor,
                          status,
                          onChange,
                        }: FavoriteButtonProps) => {
  const [isLike, setIsLike] = useState<boolean>(status ?? false);
  const queryClient = useQueryClient();

  // context 타입 정의
  interface MutationContext {
    previousStatus: boolean;
  }

  // 좋아요 상태 업데이트를 위한 mutation
  const mutation = useMutation<
    LikeResponse,
    AxiosError<LikeErrorResponse>,
    string,
    MutationContext
  >({
    mutationFn: putLike,
    // 낙관적 업데이트 적용
    onMutate: async () => {
      // 이전 상태를 저장
      const previousStatus = isLike;
      const newStatus = !isLike;
      setIsLike(newStatus);
      if (onChange) {
        onChange(id, newStatus);
      }
      return { previousStatus };
    },
    onError: (error, _, context) => {
      // 에러 발생 시 이전 상태로 롤백
      if (context) {
        setIsLike(context.previousStatus);
        if (onChange) {
          onChange(id, context.previousStatus);
        }
      }
      console.error("Failed to update like status:", error);
    },
    onSettled: () => {
      // mutation 완료 후 관련 쿼리 무효화
      queryClient.invalidateQueries({ queryKey: ["getCelebList"] });
    },
  });

  // status prop이 변경되면 내부 상태도 업데이트
  useEffect(() => {
    if (status !== undefined && status !== isLike) {
      setIsLike(status);
    }
  }, [status]);

  const handleClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    if (!mutation.isPending) {
      mutation.mutate(id);
    }
  }, [id, mutation]);

  return (
    <div
      onClick={handleClick}
      style={{
        cursor: mutation.isPending ? "wait" : "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        opacity: mutation.isPending ? 0.7 : 1,
        transition: "opacity 0.2s ease",
      }}
    >
      <FavoriteIcon size={size} color={color} status={isLike} />
    </div>
  );
};

export default FavoriteButton;
