import React from 'react';
import styled from "styled-components";
import LogoIcon from "../icon/Logo";
import { useNavigate } from "react-router-dom";
import { colors } from '../../styles/color';

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1.6rem 8rem;
  border-bottom: 0.1rem solid ${colors.neutral['300']};
  margin-bottom: 28px;
`;

const LogoSection = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 12px;
`;

const HeaderTitle = styled.span`
  color: ${colors.neutral['900']};
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
`;

const PrivacyTermHeader = () => {
  const navigate = useNavigate();

  const moveMainPage = () => {
    navigate('/');
  };

  return (
    <HeaderWrapper>
      <LogoSection>
        <div onClick={moveMainPage} style={{ cursor: "pointer" }}>
          <LogoIcon/>
        </div>
        <HeaderTitle>Privacy & Terms of Use</HeaderTitle>
      </LogoSection>
    </HeaderWrapper>
  );
};

export default PrivacyTermHeader;