import React, { useState } from "react";
import styled from "styled-components";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Apply from "../button/Apply";
import RangeSlider from "../common/RangeSlider";
import Country from "../select/Country";
import Checkbox from "../common/Checkbox";
import { CelebListFilterQuery } from "../interfaces/filter.interface";
import { colors } from "../../styles/color";
import {useAtom} from "jotai/index";
import {displayCountryAtom} from "../../atoms/filter";

const CelebListSidebarContainer = styled.div`
  border-right: 0.1rem solid ${colors.neutral["50"]};
  width: 20%;
  height: calc(100vh - 8rem);
  flex-shrink: 0;
  position: sticky;
  top: 1px;
  background-color: white;
  overflow-y: auto;

  @media (max-width: 1250px) {
    width: 100%;
    height: auto;
    position: static;
    border-right: none;
    border-bottom: 0.1rem solid ${colors.neutral["100"]};
  }

  .title {
    padding: 4rem 3.2rem 2rem;
    ${({ theme }) => theme.font.title1};
    ${({ theme }) => theme.font.bold};

    @media (max-width: 768px) {
      padding: 2rem 1.6rem;
    }
  }
`;

const FilterMenuContainer = styled.ul`
  @media (max-width: 768px) {
    padding: 0 1.6rem;
  }
`;

const FilterMenu = styled.li`
  display: flex;
  justify-content: space-between;
  padding: 2rem 3.2rem;
  border-bottom: 0.1rem solid ${colors.neutral["100"]};
  color: ${colors.neutral["900"]};
  cursor: pointer;
  white-space: nowrap;

  @media (max-width: 768px) {
    padding: 1.6rem;
  }
`;

const FilterDetail = styled.li`
  padding: 2rem 3.2rem;
  border-bottom: 0.1rem solid ${colors.neutral["100"]};
  color: ${colors.neutral["900"]};

  @media (max-width: 768px) {
    padding: 1.6rem;
  }
`;

const CheckboxContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.4rem;
`;

const celebType = [
  { label: "Musician", value: "musician" },
  { label: "Actor", value: "actor" },
  { label: "Others", value: "others" },
  { label: "Athlete", value: "athlete" },
];

interface CelebListSidebarProps {
  query: CelebListFilterQuery;
  setQuery: (name: keyof CelebListFilterQuery, value: string | number, checked?: boolean) => void;
  refetch: () => void;
}

const CelebListSidebar = ({
                            query,
                            setQuery,
                            refetch,
                          }: CelebListSidebarProps) => {
  const [celebTypeStatus, setCelebTypeStatus] = useState<boolean>(false);
  const [countryStatus, setCountryStatus] = useState<boolean>(false);
  const [_, setDisplayCountry] = useAtom(displayCountryAtom);

  const [localQuery, setLocalQuery] = useState<CelebListFilterQuery>(query);

  // 기존 queryHandler와 동일한 로직을 로컬 상태 업데이트용으로 사용
  const updateArray = (target: string[], value: string, checked: boolean) => {
    return checked ? [...target, value] : target.filter((item) => item !== value);
  };

  const localQueryHandler = (
    name: keyof CelebListFilterQuery,
    value: string | number,
    checked?: boolean
  ) => {
    setLocalQuery((prevQuery) => {
      const targetValue = prevQuery[name];
      if (
        checked !== undefined &&
        typeof value === "string" &&
        Array.isArray(targetValue)
      ) {
        return {
          ...prevQuery,
          [name]: updateArray(targetValue as string[], value, checked),
        };
      }
      return {
        ...prevQuery,
        [name]: value,
      };
    });
  };

  const handleApply = () => {
    Object.entries(localQuery).forEach(([key, value]) => {
      setQuery(key as keyof CelebListFilterQuery, value);
    });
    setDisplayCountry(localQuery.country);
    refetch();
  };

  return (
    <CelebListSidebarContainer>
      <p className="title">Filters</p>
      <FilterMenuContainer>
        <FilterMenu
          className={countryStatus ? "selected" : ""}
          onClick={() => setCountryStatus(!countryStatus)}
        >
          Country-specific Data
          {countryStatus ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </FilterMenu>
        {countryStatus && (
          <>
            <FilterDetail>
              {/* Country 컴포넌트에 로컬 query 및 업데이트 함수를 전달 */}
              <Country query={localQuery} setQuery={localQueryHandler} />
            </FilterDetail>
            <FilterDetail>
              <RangeSlider
                title="Instagram Followers"
                width={236}
                max={1000000}
                minValue={localQuery.minInstagramFollowers}
                maxValue={localQuery.maxInstagramFollowers}
                name="InstagramFollowers"
                setQuery={localQueryHandler}
              />
            </FilterDetail>
            <FilterDetail>
              <RangeSlider
                title="Search Volume"
                width={236}
                max={50000}
                minValue={localQuery.minSearchVolume}
                maxValue={localQuery.maxSearchVolume}
                name="SearchVolume"
                setQuery={localQueryHandler}
              />
            </FilterDetail>
          </>
        )}
        <FilterMenu
          className={celebTypeStatus ? "selected" : ""}
          onClick={() => setCelebTypeStatus(!celebTypeStatus)}
        >
          Celeb type
          {celebTypeStatus ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </FilterMenu>
        {celebTypeStatus && (
          <FilterDetail>
            <CheckboxContainer>
              {celebType.map((type) => (
                <Checkbox
                  key={"celeb_type_checkbox" + type.value}
                  label={type.label}
                  value={type.value}
                  checked={localQuery.type.includes(type.value)}
                  onChange={(value, checked) =>
                    localQueryHandler("type", value, checked)
                  }
                />
              ))}
            </CheckboxContainer>
          </FilterDetail>
        )}

        <FilterDetail>
          <Apply
            onApply={handleApply}
          />
        </FilterDetail>
      </FilterMenuContainer>
    </CelebListSidebarContainer>
  );
};

export default CelebListSidebar;
