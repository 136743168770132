import React from "react";
import Section from "../layout/Section";
import styled from "styled-components";
import { CelebData } from "../interfaces/celeb.interfaces";
import { SectionTitle } from "./Celeb";
import { useCelebHighlight } from "../../hooks/useCeleb";

const Wrapper = styled.div`
  max-width: 1440px;
  display: flex;
  margin: auto;
  overflow: auto;
  white-space: nowrap;
`;

const HighlightList = styled.ul`
  display: flex;
  gap: 2.2rem;
  justify-content: flex-start;
  margin-top: 2.8rem;
  width: 144rem;
  padding-bottom: 1rem; /* 스크롤바를 위한 여백 추가 */
  
  /* 스크롤바 스타일링 (선택사항) */
  &::-webkit-scrollbar {
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
`;

const Highlight = styled.li`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  width: 16rem;
  overflow: hidden;
  .title {
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.bold};
    white-space: pre-line;
    overflow: hidden;
    //text-overflow: ellipsis;
  }
  .year {
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.regular};
  }
  .highlight-thumbnail {
    position: relative;
    width: 16rem;
    height: 20rem;
    background-color: var(--gray-500);
    .thumbnail {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .logo {
      width: 3.2rem;
      height: 3.2rem;
      border-radius: 50%;
      position: absolute;
      right: 0.8rem;
      bottom: 0.8rem;
      background-color: black;
      object-fit: contain;
    }
  }
`;

interface HighlightSectionProps {
  celebData: CelebData;
}

const HighlightSection = ({ celebData }: HighlightSectionProps) => {
  const { data, isPending } = useCelebHighlight(celebData.celebInfo.id);

  if (isPending) return <>Loading</>;
  if (!data || !data.data.length) return <></>;

  return (
    <Section mt={12}>
      <SectionTitle>Highlights</SectionTitle>
      <Wrapper>
        <HighlightList>
          {data?.data.map((highlight, index) => {
            const year = highlight.movieReleaseDate?.substring(0, 4);

            if (index < 8) {
              return (
                <Highlight key={"highlight" + index}>
                  <div className="highlight-thumbnail">
                    <img
                      className="thumbnail"
                      alt="thumbnail"
                      src={highlight.imgUrl}
                    />
                    {highlight.logoImg && (
                      <img
                        className="logo"
                        alt="logo"
                        src={highlight.logoImg}
                      />
                    )}
                  </div>
                  <p className="title">{highlight.movieName}</p>
                  <p className="year">{year}</p>
                </Highlight>
              );
            }
          })}
        </HighlightList>
      </Wrapper>
    </Section>
  );
};

export default HighlightSection;
