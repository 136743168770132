import React from "react";
import styled from "styled-components";
import {
  ArrowDownCircle,
  ArrowUpCircle,
  InstagramIcon,
  TrendIcon,
} from "../../icon/Icon";
import { InstagramData, SearchTrend} from "../../interfaces/celeb.interfaces";
import { formatNumber, formatNumberWithCommas, roundFollowers } from "../../../utils/utils";
import {ECelebType} from "../celebCard/CelebCard";

const CelebDataCard = styled.div`
  border: 0.1rem solid var(--gray-900);
  border-radius: 0.4rem;
  background-color: var(--gray-050);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 32rem;
  height: 42rem;
  padding: 3.6rem;
  > .title {
    display: flex;
    width: 100%;
    gap: 0.8rem;
    align-items: center;
    ${({ theme }) => theme.font.title2};
    ${({ theme }) => theme.font.bold};
    padding-bottom: 2rem;
  }
`;

const DataList = styled.ul`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 4rem;
  padding-top: 2.2rem;
  border-top: 0.1rem solid var(--gray-900);
`;

const TotalVolume = styled.li`
  display: flex;
  justify-content: space-between;
  color: var(--gray-900);
  /* min-height: 6.6rem; */
  > .title {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    > :last-child {
      ${({ theme }) => theme.font.body3};
      ${({ theme }) => theme.font.regular};
      &.country-list {
        margin-top: 1.2rem;
        ${({ theme }) => theme.font.body1};
        ${({ theme }) => theme.font.regular};
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        margin-bottom: auto;
      }
    }
    > :first-child {
      ${({ theme }) => theme.font.body1};
      ${({ theme }) => theme.font.bold};
      white-space: pre-wrap;
    }
  }
  > .score {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;

    :last-child {
      margin-left: auto;
      ${({ theme }) => theme.font.body3};
      ${({ theme }) => theme.font.regular};
      display: flex;
      align-items: center;
      gap: 0.4rem;
    }

    :first-child {
      ${({ theme }) => theme.font.h3};
      ${({ theme }) => theme.font.bold};
      text-align: right;
    }
  }
`;

interface SearchTrendProps {
  searchTrend: SearchTrend;
  celebData: any;
}

export const DataForSeoCard = ({ searchTrend, celebData }: SearchTrendProps) => {

  return (
    <CelebDataCard>
      <div className="title">
        <TrendIcon size={2.4} /> Search Trend
      </div>
      <DataList>
        <TotalVolume>
          <div className="title">
            <p>Total volume</p>
            <p>Avg. 12 month</p>
          </div>
            <div className="score">
              <p>{formatNumberWithCommas(searchTrend.totalVolume)}</p>
              <p>
                {searchTrend.beforeTotalVolume !== null ? (
                  searchTrend.totalVolume - searchTrend.beforeTotalVolume >= 0 ? (
                    <ArrowUpCircle size={2} color="var(--crimson-600)" />
                  ) : (
                    <ArrowDownCircle size={2} color="var(--blue-500)" />
                  )
                ) : (
                  <></>
                )}
                {searchTrend.beforeTotalVolume !== null &&
                  searchTrend.beforeTotalVolume > 0 &&
                  `${(
                    searchTrend.totalVolume / searchTrend.beforeTotalVolume
                  ).toFixed(1)}%`}
              </p>
            </div>
        </TotalVolume>
        <TotalVolume>
          <div className="title">
            <p>Popular countries</p>
            <ul className="country-list">
              {
                searchTrend.topSearchPercent &&
                searchTrend.topSearchPercent.slice(0, 5).map((data) => {
                  const countryName = data.countryName === "United States"
                    ? "United States (USA)"
                    : data.countryName;
                  return <li key={data.countryIsoCode}>{countryName}</li>;
                })
              }
            </ul>
          </div>
        </TotalVolume>
      </DataList>
    </CelebDataCard>
  );
};

export default DataForSeoCard;

interface InstagramCardProps {
  instagram: InstagramData;
  celebData: any;
}

export const InstagramCard = ({ instagram, celebData }: InstagramCardProps) => {
  const celebType = celebData.celebInfo.type;

  return (
    <CelebDataCard>
      <div className="title">
        <InstagramIcon size={2.4} /> Instagram
      </div>
      <DataList>
        <TotalVolume>
          <div className="title">
            <p>Followers</p>
          </div>
          <div className="score">
            {
              celebType !== ECelebType.CLOSED ? (
                <p>
                  {instagram.followers > 0 ? formatNumber(instagram.followers) : "-"}
                </p>
              ) : (
                <p>{instagram.followers > 0 ? roundFollowers(formatNumber(instagram.followers)) : "-"}</p>
              )
            }
            <p></p>
          </div>
        </TotalVolume>
        <TotalVolume>
          <div className="title">
            <p>Reach</p>
            <p>Estimated</p>
          </div>
          <div className="score">
            <p>
              {instagram.minEstimatedReach > 0 && instagram.maxEstimatedReach > 0
                ? `${formatNumberWithCommas(instagram.minEstimatedReach)} - ${formatNumberWithCommas(instagram.maxEstimatedReach)}`
                : "-"}
            </p>
            <p></p>
          </div>
        </TotalVolume>
        {/*<TotalVolume>*/}
        {/*  <div className="title">*/}
        {/*    <p>Posting {"\n"}Cost</p>*/}
        {/*    <p>Estimated</p>*/}
        {/*  </div>*/}
        {/*  <div className="score">*/}
        {/*    <p>*/}
        {/*      ${formatNumberWithCommas(instagram.minPrice)} - $*/}
        {/*      {formatNumberWithCommas(instagram.maxPrice)}*/}
        {/*    </p>*/}
        {/*  </div>*/}
        {/*</TotalVolume>*/}
      </DataList>
    </CelebDataCard>
  );
};
