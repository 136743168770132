import React, { HTMLAttributes, ReactNode } from "react";
import styled from "styled-components";

interface ImageContainerStyleProps {
  $width?: number;
  $height?: number;
  $borderRadius?: number;
  $unit?: string;
  $overflow?: string;
  $objectFit?: string;
  $position?: string;
  $isBlurred?: boolean;
  $celebType?: string;
}

const ImageContainerStyle = styled.div<ImageContainerStyleProps>`
  width: ${({ $width }) => $width}${({ $unit }) => $unit};
  height: ${({ $height }) => $height}${({ $unit }) => $unit};
  overflow: ${(props) => props.$overflow};
  border-radius: ${(props) => props.$borderRadius}${({ $unit }) => $unit};
  position: ${({ $position }) => $position};
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: ${(props) => props.$objectFit};
    filter: ${props => (props.$isBlurred || props.$celebType === "CLOSED") ? 'blur(8px)' : 'none'};
  }
`;

interface ImgContainerProps extends HTMLAttributes<HTMLDivElement> {
  width?: number;
  height?: number;
  borderRadius?: number;
  celebType?: string;
  unit?: string;
  overflow?: string;
  objectFit?: string;
  position?: string;
  isBlurred?: boolean;
  children?: ReactNode;
}

const ImageContainer = ({
                          width,
                          height,
                          unit = "rem",
                          borderRadius = 0,
                          celebType,
                          overflow = "hidden",
                          objectFit = "cover",
                          position = "relative",
                          isBlurred = false,
                          children,
                          ...props
                        }: ImgContainerProps) => {
  return (
    <ImageContainerStyle
      $width={width}
      $height={height}
      $unit={unit}
      $borderRadius={borderRadius}
      $overflow={overflow}
      $objectFit={objectFit}
      $position={position}
      $isBlurred={isBlurred}
      $celebType={celebType}
      {...props}
    >
      {children}
    </ImageContainerStyle>
  );
};

export default ImageContainer;
