import React from 'react';
import styled from 'styled-components';

const MoveTopBtn = styled.div`
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 100;
  cursor: pointer;
  transition: opacity 0.3s ease;

  img {
    width: 40px;
    height: 40px;
    filter: brightness(100%);
    transition: filter 0.3s ease;
  }

  &:hover {
    img {
      filter: brightness(70%);
    }
  }
`;

interface MoveTopProps {
  onClick: () => void;
  show: boolean;
}

const MoveTop: React.FC<MoveTopProps> = ({ onClick, show }) => {
  if (!show) return null;

  return (
    <MoveTopBtn onClick={onClick}>
      <img src="/svg/celeblist/move_top.svg" alt="Move to Top" />
    </MoveTopBtn>
  );
};

export default MoveTop;
