import React, { useState, useRef, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { colors } from '../../../styles/color';
import ContactNowCelebCard from "./ContactNowCelebCard";

const ContactNowSection = styled.div`
  width: 100%;
  padding: 32px 0;
  background-color: ${colors.neutral["50"]};

  @media (max-width: 520px) {
    padding: 16px 0;
  }
`;

const ContactNowTitle = styled.div`
  margin-bottom: 28px; 
  margin-left: 48px;
  
  img {
    height: 32px;
    width: auto;
  }

  @media (max-width: 520px) {
    margin-left: 16px;
  }
`;

const ContactNowCelebListContainer = styled.div`
  width: calc(100% - 48px);
  position: relative;
  margin-left: 48px;
  display: flex;
  justify-content: flex-end;

  @media (min-width: 1600px) {
    max-width: 1240px;
  }

  @media (max-width: 1280px) {
    max-width: 1000px;
  }

  @media (max-width: 1024px) {
    max-width: 760px;
  }

  @media (max-width: 768px) {
    max-width: 500px;
  }

  @media (max-width: 520px) {
    width: 100%;
    max-width: 100%;
    margin-left: 0;
    padding: 0;
    box-sizing: border-box;
  }
`;

const CelebList = styled.div`
  display: flex;
  gap: 20px;
  overflow-x: hidden;
  scroll-behavior: smooth;
  position: relative;
  width: 100%;
  user-select: none;
  
  & > * {
    flex: 0 0 240px;
    width: 240px;
    height: 240px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  @media (max-width: 520px) {
    gap: 0;
    margin: 0 16px;
    & > * {
      flex: 0 0 calc(100vw - 32px);
      min-width: calc(100vw - 32px);
      width: calc(100vw - 32px);
      height: auto;
      aspect-ratio: 1;
      scroll-snap-align: start;
      pointer-events: none;
    }
    scroll-snap-type: x mandatory;
    touch-action: pan-x;
  }
`;

const StyledArrow = styled.div<{ isVisible: boolean }>`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: ${props => props.isVisible ? 'flex' : 'none'};
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;

  svg {
    font-size: 20px;
    color: white;
  }

  &.prev {
    left: 15px;
  }

  &.next {
    right: 15px;
  }

  @media (max-width: 520px) {
    &.prev {
      left: 8px;
    }
    &.next {
      right: 8px;
    }
  }
`;

// GradientOverlay 컴포넌트 수정
const GradientOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 120px;
  height: 100%;
  background: linear-gradient(
    90deg, 
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.2) 50%,
    rgba(0, 0, 0, 0.5) 100%
  );
  pointer-events: none;
  z-index: 1;

  @media (max-width: 520px) {
    display: none;
  }
`;

interface ContactNowProps {
  celebs: {
    id: string;
    name: string;
    instagramFollowersCnt: number;
    searchVolume: number;
    celebMainImg: string;
    isLike?: boolean;
    fakeKrName?: string;
    fakeEnName?: string;
    birthday?: string;
    minSearchVolume?: number;
    maxSearchVolume?: number;
    minPrice?: number;
  }[];
  onFavoriteUpdate: (id: string, status: boolean) => void;
}

const ContactNow: React.FC<ContactNowProps> = ({ celebs, onFavoriteUpdate }) => {
  const listRef = useRef<HTMLDivElement>(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const [visibleCards, setVisibleCards] = useState(5);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const cardWidth = 240;
  const gap = 20;

  // 화면 크기에 따라 보여질 카드 수 조정
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width >= 1280) {
        setVisibleCards(5);
      } else if (width >= 1024) {
        setVisibleCards(4);
      } else if (width >= 768) {
        setVisibleCards(3);
      } else if (width >= 520) {
        setVisibleCards(2);
      } else {
        setVisibleCards(1);
      }
    };

    handleResize(); // 초기 실행
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const checkScroll = useCallback(() => {
    if (listRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = listRef.current;
      setScrollPosition(scrollLeft);
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(
        Math.ceil(scrollLeft) < scrollWidth - clientWidth && celebs.length > visibleCards
      );
    }
  }, [celebs.length, visibleCards]);

  useEffect(() => {
    checkScroll();
    window.addEventListener('resize', checkScroll);
    return () => window.removeEventListener('resize', checkScroll);
  }, [checkScroll]);

  const scroll = (direction: 'left' | 'right') => {
    if (listRef.current) {
      const scrollAmount = window.innerWidth <= 520
        ? listRef.current.clientWidth
        : (cardWidth + gap);
      const newPosition = scrollPosition + (scrollAmount * (direction === 'left' ? -1 : 1));

      listRef.current.scrollTo({
        left: newPosition,
        behavior: 'smooth'
      });
    }
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    setIsDragging(true);
    setStartX(e.pageX - listRef.current!.offsetLeft);
    setScrollLeft(listRef.current!.scrollLeft);
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    setIsDragging(true);
    setStartX(e.touches[0].pageX - listRef.current!.offsetLeft);
    setScrollLeft(listRef.current!.scrollLeft);
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - listRef.current!.offsetLeft;
    const walk = (x - startX) * 2;
    listRef.current!.scrollLeft = scrollLeft - walk;
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (!isDragging) return;
    const x = e.touches[0].pageX - listRef.current!.offsetLeft;
    const walk = (x - startX) * 2;
    listRef.current!.scrollLeft = scrollLeft - walk;
  };

  const stopDragging = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    document.addEventListener('mouseup', stopDragging);
    document.addEventListener('touchend', stopDragging);
    return () => {
      document.removeEventListener('mouseup', stopDragging);
      document.removeEventListener('touchend', stopDragging);
    };
  }, []);

  return (
    <ContactNowSection>
      <ContactNowTitle>
        <img src="/svg/celeblist/contact_now.svg" alt="contact_now" />
      </ContactNowTitle>
      <ContactNowCelebListContainer>
        <StyledArrow
          className="prev"
          isVisible={canScrollLeft}
          onClick={() => scroll('left')}
        >
          <ArrowBackIosIcon />
        </StyledArrow>
        <CelebList
          ref={listRef}
          onScroll={checkScroll}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseLeave={stopDragging}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
        >
          {celebs.map((celeb) => (
            <ContactNowCelebCard
              key={celeb.id}
              celeb={celeb}
              onFavoriteUpdate={onFavoriteUpdate}
            />
          ))}
        </CelebList>
        <StyledArrow
          className="next"
          isVisible={canScrollRight}
          onClick={() => scroll('right')}
        >
          <ArrowForwardIosIcon />
        </StyledArrow>
        <GradientOverlay />
      </ContactNowCelebListContainer>
    </ContactNowSection>
  );
};

export default ContactNow;
