import React from 'react';
import { Section, MainSection, SubSection } from '../CommonStyles';

const DataManagementSection = () => {
  return (
    <>
   <Section id="data-management-and-privacy" marginTop={20}>
					<MainSection>III. Data Management and Privacy</MainSection>
				</Section>
				<Section marginTop={0}>
					<SubSection>10. ColLegal Matterslection and Transmission of Information</SubSection>
				</Section>
				<Section marginTop={-20}>
					<p>The company may collect behavioral information from users (celebrities, agencies, and advertisers) during
						the use of the service to provide enhanced services. The information collected may include, but is not
						limited to, the following:</p>
				</Section>
				<Section marginTop={-20}>
					<p>
						<span>1.</span>
						<span>Collection of Behavioral Information</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p className="nested">
						<span>a.</span>
						<span>Access times and frequency</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p className="nested">
						<span>b.</span>
						<span>Click and scroll patterns</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p className="nested">
						<span>c.</span>
						<span>(For advertisers) Viewing and visit records of celebrity pages</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p className="nested">
						<span>d.</span>
						<span>Details and history of contract creation</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p className="nested">
						<span>d.</span>
						<span>Details and history of contract creation</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p>
						<span>2.</span>
						<span>The collected behavioral information is securely stored on the company's servers, and Aligo implements reasonable technical and administrative measures to protect it. Except when required to comply with legal obligations, the collected information shall not be disclosed to third parties.</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p>
						<span>3.</span>
						<span>Transmission and Sharing of Information:</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p className="nested">
						<span>a.</span>
						<span>The company may transmit or share the collected behavioral information only under the following circumstances:</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p className="nested roman">
						<span>i.</span>
						<span>When necessary for the execution of contracts with advertisers or partner companies</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p className="nested roman">
						<span>ii.</span>
						<span>When used for internal analysis to improve the service</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p className="nested roman">
						<span>iii.</span>
						<span>When required to provide information in response to legal requests in accordance with applicable laws</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p>
						<span>4.</span>
						<span>Consent to Information Provision</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p className="nested">
						<span>a.</span>
						<span>Users must explicitly consent to the collection and transmission of behavioral information, and such consent is obtained through the acceptance of the terms and conditions provided during service use. Users may refuse or withdraw their consent to information collection at any time; however, this may result in certain restrictions on the use of some services.</span>
					</p>
				</Section>
				<Section marginTop={0}>
					<SubSection>11. Rights and Obligations</SubSection>
				</Section>
				<Section marginTop={-20}>
					<p>
						<span>1.</span>
						<span>The advertiser must consult with the agency in advance regarding all relevant matters during the contract process, while the agency and the celebrity must faithfully fulfill the obligations stipulated in the finalized contract.</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p>
						<span>2.</span>
						<span>The advertiser shall not be held liable for unauthorized reproduction, distribution, or use of the campaign materials by third parties unless such actions result from the advertiser’s intentional misconduct or negligence</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p>
						<span>3.</span>
						<span>The agency and the celebrity shall not engage in any actions or statements during the contract period that could damage the reputation, credibility, or corporate image of the advertiser or the advertiser’s brand.</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p>
						<span>4.</span>
						<span>The agency and the celebrity represent and warrant that there are no contractual relationships or legal or factual restrictions with third parties that could limit the execution or fulfillment of the contract.</span>
					</p>
				</Section>
    </>
  );
};

export default DataManagementSection;