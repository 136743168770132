import React from 'react';
import { Section, MainSection, SubSection } from '../CommonStyles';

const LegalMattersSections = () => {
  return (
    <>
      <Section id="legal-matters" marginTop={20}>
        <MainSection>IV. Legal Matters</MainSection>
      </Section>

      <Section marginTop={0}>
        <SubSection>12. Intellectual Property Rights</SubSection>
      </Section>

      <Section marginTop={-20}>
        <p>
          <span>The advertiser shall own the intellectual property rights to the advertising materials created under contracts concluded through Celevu. The agency and the celebrity shall not claim any intellectual property rights to these materials. However, the advertiser is permitted to use the advertising materials only during the contract period, and their use is restricted after the contract's termination unless otherwise agreed in writing.</span>
        </p>
      </Section>

      <Section marginTop={0}>
        <SubSection>13. Compensation for Damages</SubSection>
      </Section>

      <Section marginTop={-20}>
        <p>
          <span>If one party breaches or fails to fulfill this contract, causing damage to the other party, the responsible party shall compensate for the resulting damages.</span>
        </p>
      </Section>

      <Section marginTop={0}>
        <SubSection>14. Confidentiality</SubSection>
      </Section>

      <Section marginTop={-20}>
        <p>
          <span>The parties to this contract shall not disclose or leak any information provided or disclosed under this contract to a third party without prior written consent from the other party.</span>
        </p>
      </Section>

      <Section marginTop={0}>
        <SubSection>15. Application and Interpretation of the Contract</SubSection>
      </Section>

      <Section marginTop={-20}>
        <p>
          <span>1.</span>
          <span>For matters not specified in these terms or where differences in interpretation arise, both parties shall resolve them amicably through mutual agreement in good faith. If an agreement cannot be reached, the matters shall be governed by applicable laws and established practices.</span>
        </p>
      </Section>

      <Section marginTop={-20}>
        <p>
          <span>2.</span>
          <span>In the event of legal disputes related to these terms, the Seoul Central District Court shall have exclusive jurisdiction by mutual agreement.</span>
        </p>
      </Section>

      <Section marginTop={10}>
        <SubSection>16. Effectiveness and Termination of the Contract</SubSection>
      </Section>

      <Section marginTop={-20}>
        <p>
          <span>These terms shall take effect immediately upon registration.</span>
        </p>
      </Section>
    </>
  );
};

export default LegalMattersSections;