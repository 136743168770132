import React from "react";
import { CelebData } from "../../interfaces/celeb.interfaces";
import Section from "../../layout/Section";
import styled from "styled-components";
import DataForSeoCard, { InstagramCard } from "./DataCard";
import NewsCard from "./NewsCard";

const Wrapper = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: center;
  overflow-x: auto;
  padding: 1rem;
  
  /* 스크롤바 스타일링 (선택사항) */
  &::-webkit-scrollbar {
    height: 8px;
  }
  
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  @media screen and (max-width: 1250px) {
    flex-direction: column;
    overflow-x: visible; /* 모바일에서는 세로 스크롤만 필요 */
  }
`;

interface CelebSummarySectionProps {
  celebData: CelebData;
}

const CelebSummarySection = ({ celebData }: CelebSummarySectionProps) => {
  const searchTrend = celebData.searchTrend;
  const searchTrendStatus = Object.keys(searchTrend).length !== 0;
  const instagram = celebData.instagram;
  const instagramStatus = Object.keys(instagram).length !== 0;
  const newsList = celebData.celebInfo.celebNews;
  const newsListStatus = celebData.celebInfo.celebNews.length !== 0;

  return (
    <Section mt={8}>
      <Wrapper>
        {newsListStatus && (
          <NewsCard
            celebType={celebData.celebInfo.type}
            newsList={newsList}
            dataStatus={searchTrendStatus && instagramStatus}
          />
        )}
        {searchTrendStatus && instagramStatus && (
          <>
            <DataForSeoCard
              searchTrend={searchTrend}
              celebData={celebData}
            />
            {
              instagram && <InstagramCard instagram={instagram} celebData={celebData} />
            }
          </>
        )}
      </Wrapper>
    </Section>
  );
};

export default CelebSummarySection;
