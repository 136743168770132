import React from "react";
import styled, { css } from "styled-components";
import { sizeStyle } from "../common/SizeStyle";

type colorType = "black" | "white" | "purple" | "default" | "gray";
type sizeType = "xs" | "sm" | "md" | "lg" | "default";

const colorStyle = {
  disabled: css`
    border: 0.1rem solid transparent;
    border-radius: 0.4rem;
    background-color: var(--gray-500);
    color: var(--gray-050);
  `,
  black: css`
    border: 0.1rem solid transparent;
    border-radius: 0.4rem;
    background-color: var(--gray-900);
    color: var(--gray-050);
  `,
  gray: css`
    border: 0.1rem solid transparent;
    border-radius: 0.4rem;
    background-color: #A4A4A4;
    color: var(--gray-050);
  `,
  white: css`
    border: 0.1rem solid var(--gray-900);
    border-radius: 0.4rem;
    background-color: var(--gray-050);
    color: var(--gray-900);
  `,
  purple: css`
    border: 0.1rem solid transparent;
    border-radius: 0.4rem;
    background-color: var(--purple-300);
    color: var(--gray-050);
  `,
  default: css`
    border: 0.1rem solid transparent;
    border-radius: 0.4rem;
    background-color: var(--gray-900);
    color: var(--gray-050);
  `,
};

interface ButtonProps {
  $size: sizeType;
  $color: colorType;
  $margin?: string;
  $marginTop?: number;
  $marginRight?: number;
  $marginBottom?: number;
  $marginLeft?: number;
  $unit?: string;
}

const Button = styled.button<ButtonProps>`
  text-align: center;
  ${({ $size }) => sizeStyle[$size]};
  ${({ $color }) => colorStyle[$color]};
  ${({ $margin }) => ($margin ? `margin: ${$margin}` : "")};
  ${({ $marginTop, $unit }) =>
    $marginTop ? `margin-top: ${$marginTop}` + $unit : ""};
  ${({ $marginRight, $unit }) =>
    $marginRight ? `margin-right: ${$marginRight}` + $unit : ""};
  ${({ $marginBottom, $unit }) =>
    $marginBottom ? `margin-bottom: ${$marginBottom}` + $unit : ""};
  ${({ $marginLeft, $unit }) =>
    $marginLeft ? `margin-left: ${$marginLeft}` + $unit : ""};
  &:disabled {
    ${colorStyle.disabled}
    cursor: not-allowed;
  }
`;

interface StyledButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  size?: sizeType;
  color?: colorType;
  margin?: string;
  mt?: number;
  mr?: number;
  mb?: number;
  ml?: number;
  unit?: string;
}

const StyledButton = ({
  label,
  size = "default",
  color = "default",
  margin,
  mt,
  mr,
  mb,
  ml,
  unit = "rem",
  ...options
}: StyledButtonProps) => {
  return (
    <Button
      $size={size}
      $color={color}
      $margin={margin}
      $marginTop={mt}
      $marginRight={mr}
      $marginBottom={mb}
      $marginLeft={ml}
      $unit={unit}
      {...options}
    >
      {label}
    </Button>
  );
};

export default StyledButton;
