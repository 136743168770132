export function formatNumber(num: number): string {
  if (Math.abs(num) >= 1000000000) {
    return (num / 1000000000).toFixed(1) + "B";
  } else if (Math.abs(num) >= 1000000) {
    return (num / 1000000).toFixed(1) + "M";
  } else if (Math.abs(num) >= 1000) {
    return (num / 1000).toFixed(1) + "K";
  }
  return num.toString();
}

export function roundFollowers(numString: string): string {
  const unit = numString.slice(-1);
  const num = parseFloat(numString.slice(0, -1));

  const numDigits = Math.floor(Math.log10(num)) + 1;
  const roundingFactor = Math.pow(10, numDigits - 1);
  const rounded = Math.round(num / roundingFactor) * roundingFactor;
  const result = rounded + unit;
  if (result === "1000K") return "1M";
  return result;
}

export function formatNumberWithCommas(num: number | null): string {
  if (num !== null && num !== undefined)
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  else return "";
}

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return date
    .toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    })
    .replace(",", ".");
};

export default formatDate;

export const countryList = [
  { id: "WORLD_WIDE", value: "WW", label: "World Wide" },
  { id: "SOUTH_KOREA", value: "KR", label: "South Korea" },
  { id: "USA", value: "US", label: "USA" },
  { id: "JAPAN", value: "JP", label: "Japan" },
  { id: "INDONESIA", value: "ID", label: "Indonesia" },
  { id: "BRAZIL", value: "BR", label: "Brazil" },
  { id: "MEXICO", value: "MX", label: "Mexico" },
  { id: "IRAN", value: "IR", label: "Iran" },
  { id: "PHILIPPINES", value: "PH", label: "Philippines" },
  { id: "THAILAND", value: "TH", label: "Thailand" },
  { id: "FRANCE", value: "FR", label: "France" },
];

export const mediaList = [
  {
    id: "INSTAGRAM",
    value: "INSTAGRAM",
    label: "Instagram",
    img: "/svg/instagram.svg",
    imgDisabled: "/svg/instagram_disabled.svg",
  },
  {
    id: "FACEBOOK",
    value: "FACEBOOK",
    label: "Facebook",
    img: "/svg/facebook.svg",
    imgDisabled: "/svg/facebook_disabled.svg",
  },
  {
    id: "YOUTUBE",
    value: "YOUTUBE",
    label: "Youtube",
    img: "/svg/youtube.svg",
    imgDisabled: "/svg/youtube_disabled.svg",
  },
  {
    id: "TWITTER",
    value: "TWITTER",
    label: "X",
    img: "/svg/x.svg",
    imgDisabled: "/svg/x_disabled.svg",
  },
  {
    id: "PINTEREST",
    value: "PINTEREST",
    label: "Pinterest",
    img: "/svg/pinterest.svg",
    imgDisabled: "/svg/pinterest_disabled.svg",
  },
  {
    id: "TIKTOK",
    value: "TIKTOK",
    label: "Tik Tok",
    img: "/svg/tiktok.svg",
    imgDisabled: "/svg/tiktok_disabled.svg",
  },
];

export const currencyList = [
  {
    id: "USD",
    value: "USD",
    label: "USD",
    sign: "$",
  },
  {
    id: "KRW",
    value: "KRW",
    label: "KRW",
    sign: "₩",
  },
  // {
  //   id: "EUR",
  //   value: "EUR",
  //   label: "EUR",
  //   sign: "€",
  // },
];

interface StatusItem {
  value: string;
  label: string;
  statusLabel: string;
}

export const findMatchingStatusLabel = (
  array: StatusItem[],
  value: string | null
): string => {
  return array.find((item) => item.value === value)?.statusLabel || "";
};
