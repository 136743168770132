import React, {useCallback, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import { CelebListData } from "../interfaces/celeb.interfaces";
import CelebCard from "./celebCard/CelebCard";

const CardGrid = styled.div`
  margin-left: 40px;
  margin-right: 40px; // 64px 로 되어있으나 40px 가 나을것같아 40px 로 수정
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;

  @media (max-width: 1250px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const MoveTopBtn = styled.div`
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 100;
  cursor: pointer;
  transition: opacity 0.3s ease;

  img {
    width: 40px;
    height: 40px;
    filter: brightness(100%);  // 기본 색상
    transition: filter 0.3s ease;
  }

  &:hover {
    img {
      filter: brightness(70%);  // dark mode
    }
  }
`;

const LoadingContainer = styled.div`
  grid-column: 1 / -1;  // grid의 전체 width를 차지
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const LoadingImage = styled.img`
  width: 200px;
  height: 100px;
`;

interface CelebCardListProps {
  celebList: CelebListData[];
  hasMore: boolean;
  loading: boolean;
  onLoadMore: () => void;
  onFavoriteUpdate: (celebId: string, newStatus: boolean) => void;
}

const CelebCardList = ({
                         celebList,
                         hasMore,
                         loading,
                         onLoadMore,
                         onFavoriteUpdate,
}: CelebCardListProps,) => {
  const [showTopButton, setShowTopButton] = useState(false);

  const observer = useRef<IntersectionObserver | null>(null);
  const lastCelebElementRef = useCallback(
    (node: HTMLDivElement) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setTimeout(() => {
            onLoadMore();
          }, 1000);
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading, hasMore, onLoadMore]
  );

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  // 스크롤 이벤트 처리
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setShowTopButton(true);
      } else {
        setShowTopButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <CardGrid>
        {celebList?.length > 0 ? (
          celebList.map((data, index) => (
            <div
              key={data.id}
              ref={index === celebList.length - 1 ? lastCelebElementRef : null}
            >
              <CelebCard
                celebData={{
                  ...data,
                  onFavoriteStatusChange: (celebId, newStatus) => onFavoriteUpdate(celebId, newStatus)
                }}
              />
            </div>
          ))
        ) : (
          <div>
            No matching celebrities found
          </div>
        )}
        {loading && (
          <LoadingContainer>
            <LoadingImage src="/svg/celeblist/loading.gif" alt="Loading..." />
          </LoadingContainer>
        )}
      </CardGrid>
      {showTopButton && (
        <MoveTopBtn onClick={scrollToTop}>
          <img src={"/svg/celeblist/move_top.svg"} alt={"move_top"} />
        </MoveTopBtn>
      )}
    </>
  );
};

export default CelebCardList;
