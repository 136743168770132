import React from 'react';
import {useNavigate} from "react-router-dom";
import FavoriteButton from "../../button/FavoriteButton";
import {formatNumber} from "../../../utils/utils";
import styled from "styled-components";

const CelebInfo = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 16px;
	z-index: 2;
	color: white;

	.name {
		color: var(--grey-white, #FFF);
		font-size: 24px;
		font-style: normal;
		font-weight: 700;
		line-height: 120%;
		margin-bottom: 8px;
		text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
		white-space: nowrap; // 텍스트가 길어도 한줄로 표시
		//overflow: hidden;
		//text-overflow: ellipsis; // 텍스트가 너무 길면 ...으로 표시이긴한데...
	}

	.stats {
		display: flex;
		align-items: center;
		gap: 12px;

		.stat-item {
			display: flex;
			align-items: center;
			gap: 4px;
			font-size: 14px;
			color: white;

			img {
				width: 16px;
				height: 16px;
				filter: brightness(0) invert(1);
			}
		}
	}
`;

const Card = styled.div`
	display: flex;
	width: 240px;
	height: 240px;
	flex-direction: column;
	align-items: flex-start;
	position: relative;
	overflow: hidden;
	cursor: pointer;
	border-radius: 8px;
	margin: 0 auto;

	.image-container {
		position: relative;
		width: 100%;
		height: 100%;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		.overlay {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: linear-gradient(
				180deg,
				rgba(0, 0, 0, 0) 0%,
				rgba(0, 0, 0, 0.5) 50%,
				rgba(0, 0, 0, 0.8) 100%
			);
			z-index: 1;
		}
	}
`;

const FavoriteIconWrapper = styled.div`
	position: absolute;
	top: 8px;
	left: 8px;
	z-index: 2;
`;

interface CelebCardProps {
	celeb: {
		id: string;
		name: string;
		instagramFollowersCnt: number;
		searchVolume: number;
		celebMainImg: string;
		isLike?: boolean;
	};
	onFavoriteUpdate: (id: string, status: boolean) => void;
}

const ContactNowCelebCard: React.FC<CelebCardProps> = ({ celeb, onFavoriteUpdate }) => {
	const navigate = useNavigate();

	const handleCelebClick = () => {
		navigate(`/celebs/${celeb.id}`);
	}

	return (
		<Card onClick={handleCelebClick}>
			<FavoriteIconWrapper>
				<FavoriteButton
					id={celeb.id}
					size={32}
					status={celeb.isLike || false}
					color={{
						true: "var(--crimson-600)",
						false: "white"
					}}
					onChange={onFavoriteUpdate}
				/>
			</FavoriteIconWrapper>
			<div className="image-container">
				<img src={celeb.celebMainImg} alt={celeb.name} />
				<div className="overlay" />
				<CelebInfo>
					<div className="name">{celeb.name}</div>
					<div className="stats">
						<div className="stat-item">
							<img src="/svg/celeblist/Instagram.svg" alt="Instagram" />
							{formatNumber(celeb.instagramFollowersCnt)}
						</div>
						<div className="stat-item">
							<img src="/svg/celeblist/timeline.svg" alt="Timeline" />
							{formatNumber(celeb.searchVolume)}
						</div>
					</div>
				</CelebInfo>
			</div>
		</Card>
	);
};

export default ContactNowCelebCard;
