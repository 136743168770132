import React, { ReactNode } from "react";
import Navbar from "./Navbar";
import NavBack from "./NavBack";
import Modals from "../../modals/Modals";
import styled from "styled-components";
import Footer from "./Footer";

interface LayoutProps {
  children: ReactNode;
}

const MaxWidthContainer = styled.div`
  max-width: 1600px;
  margin: auto;
`;

const Layout = ({ children }: LayoutProps) => {
  return (
    <MaxWidthContainer>
      <Modals />
      <Navbar />
      {children}
      <Footer />
    </MaxWidthContainer>
  );
};

export const LayoutWithOutFooter = ({ children }: LayoutProps) => {
  return (
    <MaxWidthContainer>
      <Modals />
      <Navbar />
      {children}
    </MaxWidthContainer>
  );
};

export const LayoutWithNavBack = ({ children }: LayoutProps) => {
  return (
    <MaxWidthContainer>
      <Modals />
      <NavBack />
      {children}
    </MaxWidthContainer>
  );
};

export const LayoutWithOutNavbar = ({ children }: LayoutProps) => {
  return (
    <MaxWidthContainer>
      <Modals />
      {children}
    </MaxWidthContainer>
  );
};

export default Layout;
