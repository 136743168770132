import React from 'react';
import styled from 'styled-components';
import {colors} from "../../styles/color";

const Overlay = styled.div`
	position: fixed;
	z-index: 10;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.3); /* Dimmed background */
	backdrop-filter: blur(1px); /* Blur effect */
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Wrapper = styled.div`
	background-color: ${colors.neutral[200]};
	width: 412px;
	padding: 32px 20px;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
	justify-content: center;
	align-items: center;
	gap: 10px;
`;

const IconContainer = styled.div`
	background: #E8F5E9; /* Light yellow */
	color: #f59e0b; /* Icon color */
	border: 1px solid #388E3C;
	border-radius: 50%;
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 24px;
	margin: auto;

	img {
		border-radius: 50%;
		width: 12px;
		height: 12px;
	}
`;

const Title = styled.div`
	display: flex;
	justify-content: center; /* 수평 정렬 */
	align-items: center; /* 수직 정렬 */
	font-size: 20px;
	font-weight: 600;
	color: #252525;
	margin: 16px 0; /* 위아래 여백 추가 */
	text-align: center; /* 텍스트 내부의 중앙 정렬 */
`;

const Button = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center; /* 텍스트 내부의 중앙 정렬 */
	width: 160px;
	height: 60px;
	font-size: 16px;
	flex-shrink: 0;
	color: #FBFBFB;
	background-color: #252525;
	margin-top: 40px; /* 상단 여백 추가 */
	margin-left: auto;
	margin-right: auto; /* 수평 가운데 정렬 */

	&:hover {
		cursor: pointer;
		background-color: ${colors.neutral[800]};
	}
`;

interface IPermissionGrantModalProps {
	onClose: () => void; // Function to close the modal
}

const PermissionGrantModals = ({ onClose }: IPermissionGrantModalProps) => {

	return (
		<Overlay>
			<Wrapper>
				<IconContainer>
					<img src={"/image/check_permission.png"} className={"icon"} alt={"profiling"}/>
				</IconContainer>
				<Title>The request was successful.</Title>
				<Button onClick={onClose}>OK</Button>
			</Wrapper>
		</Overlay>
	);
};

export default PermissionGrantModals;
