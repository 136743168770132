import React from "react";
import CelebSummarySection from "./summary/CelebSummarySection";
import HighlightSection from "./HighlightSection";
import styled from "styled-components";
import ReportSection from "./ReportSection";
import CelebNameSection from "./CelebNameSection";
import CelebInformationSection from "./CelebInformationSection";
import { useParams } from "react-router-dom";
import { getCeleb } from "../../api/celebList";
import { useQuery } from "@tanstack/react-query";
import Layout from "../layout/Layout";
import { CelebResponse } from "../interfaces/celeb.interfaces";
import {useAtom} from "jotai/index";
import {CelebListFilterQuery} from "../interfaces/filter.interface";
import {celebFilterAtom} from "../../atoms/filter";

export const SectionTitle = styled.h3`
  margin: auto;
  max-width: 1440px;
  ${({theme}) => theme.font.h3};
  ${({theme}) => theme.font.bold};
`;

const Celeb = () => {
  const { id } = useParams();
  const [query, setQuery] = useAtom<CelebListFilterQuery>(celebFilterAtom);

  const { data, isPending } = useQuery<CelebResponse>({
    queryKey: [id, "getCelebData"],
    queryFn: () => getCeleb(id, query.country),
  });

  if (isPending) return <></>;
  if (!data) return <></>;

  return (
    <Layout>
      <CelebNameSection celebInfo={data.data.celebInfo} />
      <CelebInformationSection celebData={data.data} />
      <CelebSummarySection celebData={data.data} />
      <HighlightSection celebData={data.data} />
      <ReportSection celebData={data.data} />
    </Layout>
  );
};

export default Celeb;
