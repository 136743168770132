import React, { useState } from "react";
import styled from "styled-components";
import { CelebData } from "../interfaces/celeb.interfaces";
import StyledButton from "../button/StyledButton";
import useStockCartList from "../../hooks/useStockCartList";
import { formatNumberWithCommas } from "../../utils/utils";
import {ECelebType} from "./celebCard/CelebCard";
import { colors } from "../../styles/color";
import PermissionGrantModals from "../modals/PermissionGrantModals";
import ClosedPermissionRequestModals from "../modals/ClosedPermissionRequestModals";
import ClosedPermissionPendingModals from "../modals/ClosedPermissionPendingModals";
import ClosedPermissionApprovedModals from "../modals/ClosedPermissionApprovedModals";

const Overlay = styled.div`
	position: fixed;
	z-index: 2;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Wrapper = styled.div`
  width: 32rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

const ClosedWrapper = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

const Subtitle = styled.span`
  display: inline-block;
  ${({ theme }) => theme.font.title3};
  ${({ theme }) => theme.font.bold};
  margin-top: 2.4rem;
`;

const DetailText = styled.span`
  ${({ theme }) => theme.font.body1};
  ${({ theme }) => theme.font.regular};
  line-height: 130%;
`;

const ButtonList = styled.div`
  display: flex;
  margin-top: 4rem;
`;

const Description = styled.div`
  ${({ theme }) => theme.font.title3};
  ${({ theme }) => theme.font.regular};
  margin-top: 4rem;
  color: var(--gray-600);
`;

const ClosedDescription = styled.div`
  width: 100%;
  height: 120px;
  color: ${colors.neutral["900"]};
  line-height: 150%;
  
  font-size: 18px;
  font-weight: 400;
  word-wrap: break-word; /* 긴 단어를 줄바꿈 */
  overflow-wrap: break-word; /* 브라우저 호환성을 위한 추가 설정 */
`;

const DeActiveRequestPermission = styled.div`
  background-color: ${colors.neutral[600]};
  color: #FBFBFB;
  display: flex;
  width: 320px;
  height: 60px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

const RequestPermission = styled.div`
  background-color: #252525;
  color: #FBFBFB;
  display: flex;
  width: 320px;
  height: 60px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  
  &:hover {
    cursor: pointer;
    background-color: ${colors.neutral[800]};
  }
`;

export const PermissionRequestGrantMessage = styled.div`
  display: flex;
  width: 320px;
  padding: 16px 16px 24px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  border-radius: 4px;
  background: #E8F5E9;

  img {
    border: 1px solid #43A047;
    border-radius: 50%;
    color: #43A047;
    padding: 8px;
    width: 28px;
    height: 28px;
    margin: auto;
  }

  .title {
    color: #43A047;
    text-align: center;
    margin: auto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 19.2px */
  }

  .message {
    color: #43A047;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 18.2px */
  }

  .contact {
    color: #43A047;
    font-size: 14px;
    text-align: center;
    line-height: 130%;
  }

  .email {
    color: #43A047;
    font-weight: bold;
    text-decoration-line: underline;
    text-decoration-style: solid;
    margin: auto;
  }
`;

export enum EPermissionStatus {
  NOT_REQUESTED = 'NOT_REQUESTED', // 요청 전
  PENDING = 'PENDING', // 처리 대기 중
  GRANTED = 'GRANTED', // 처리 완료
}

interface CelebSideInformationProps {
  celebData: CelebData;
  selectedIndex: number;
}

const CelebSideInformation = ({
                                celebData,
                                selectedIndex,
                              }: CelebSideInformationProps) => {
  const celebPhotoList = celebData.celebStockData;
  const celebId = celebData.celebInfo.id;
  const celebPermissionStatus = celebData.celebInfo.permission;
  const { addStock } = useStockCartList(celebId);

  const [showModal, setShowModal] = useState(false);

  const [showRequestSuccess, setShowRequestSuccess] = useState(false);
  const [showPendingModal, setShowPendingModal] = useState(true);
  const [showApprovedModal, setShowApprovedModal] = useState(true);
  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const [isClosedRequestPermissionBtn, setIsClosedRequestPermissionBtn] = useState(false);
  const [isGrantModal, setIsGrantModal] = useState(false);


  const closeModal = () => {
    setShowModal(false);
  };

  const closePendingModal = () => {
    setShowPendingModal(false);
  };

  const closedRequestSuccess = () => {
    setShowRequestSuccess(false);
  }

  const closeApprovedModal = () => {
    setShowApprovedModal(false);
  };

  const closeGrantModal = () => {
    setIsSuccessModal(false);
    window.location.reload();
  };

  const changeClosedRequestPermissionBtn = () => {
    setIsClosedRequestPermissionBtn(true);
    setIsGrantModal(true);
  }

  return (
    <>
      {
        celebData.celebInfo.type === ECelebType.CLOSED ? (
          <ClosedWrapper>
              <ClosedDescription>
                {celebData.celebInfo.description}
              </ClosedDescription>
            {(isClosedRequestPermissionBtn && isGrantModal) && (
              <Overlay>
                <PermissionGrantModals onClose={closedRequestSuccess} />
              </Overlay>
            )}

            {celebPermissionStatus === EPermissionStatus.PENDING && showPendingModal && (
                <Overlay>
                  <ClosedPermissionPendingModals onClose={closePendingModal} />
                </Overlay>
              )}
              {celebPermissionStatus === EPermissionStatus.GRANTED && showApprovedModal && (
                <Overlay>
                  <ClosedPermissionApprovedModals onClose={closeApprovedModal} />
                </Overlay>
              )}

            {showModal && (
              <div>
                <ClosedPermissionRequestModals
                  onClose={closeModal}
                  celebId={celebId}
                  setIsSuccessModal={setIsSuccessModal}
                  changeClosedRequestPermissionBtn={changeClosedRequestPermissionBtn}
                />
              </div>
            )}
            {isSuccessModal && (
              <div>
                <PermissionGrantModals onClose={closeGrantModal} />
              </div>
            )}
          </ClosedWrapper>
        ) : (
          <Wrapper>
            <Subtitle>
              Photo ID :{" "}
              <DetailText>{celebPhotoList[selectedIndex].photoId}</DetailText>
            </Subtitle>
            <Subtitle>Photo Details : </Subtitle>
            <DetailText>
              {celebPhotoList[selectedIndex].extension
                ? celebPhotoList[selectedIndex].extension?.slice(1).toUpperCase()
                : ""}
            </DetailText>
            <DetailText>
              {celebPhotoList[selectedIndex].width &&
              celebPhotoList[selectedIndex].height
                ? "Max size : " +
                formatNumberWithCommas(celebPhotoList[selectedIndex].width) +
                " x " +
                formatNumberWithCommas(celebPhotoList[selectedIndex].height) +
                " px"
                : ""}
            </DetailText>
            <Subtitle>Photo Contributor : </Subtitle>
            <DetailText>{celebPhotoList[selectedIndex].photoContributor}</DetailText>
            <Description>{celebPhotoList[selectedIndex].description}</Description>
            <ButtonList>
              <StyledButton
                label="TRY"
                color="white"
                size="md"
                onClick={() =>
                  window.open(celebPhotoList[selectedIndex].imgUrl, "_blank")
                }
              />
              <StyledButton
                label="ADD"
                color="black"
                size="md"
                onClick={() => addStock(celebPhotoList[selectedIndex])}
              />
            </ButtonList>
          </Wrapper>
        )
      }
    </>
  );
};

export default CelebSideInformation;
