import React from 'react';
import { Section, MainSection } from '../CommonStyles';

const MarketingSection = () => {
  return (
    <>
      <Section id="marketing" marginTop={20}>
        <MainSection>[Marketing] Collection of User Information</MainSection>
      </Section>

      <Section marginTop={-20}>
        <p>
          <span>Keeping your personal information safe is one of our top priorities. Please review and confirm your consent below</span>
        </p>
      </Section>

      <Section marginTop={-20}>
        <p>
          <span>1.</span>
          <span>Purpose of Collection and Use</span>
        </p>
      </Section>

      <Section marginTop={-20}>
        <p className="nested">
          <span>a.</span>
          <span>All service notifications.</span>
        </p>
      </Section>

      <Section marginTop={-20}>
        <p className="nested roman">
          <span>i.</span>
          <span>Contract requests</span>
        </p>
      </Section>

      <Section marginTop={-20}>
        <p className="nested roman">
          <span>ii.</span>
          <span>Celevu web messaging tool inbox</span>
        </p>
      </Section>

      <Section marginTop={-20}>
        <p className="nested roman">
          <span>iii.</span>
          <span>Profile view requests</span>
        </p>
      </Section>

      <Section marginTop={-20}>
        <p>
          <span>2.</span>
          <span>Items</span>
        </p>
      </Section>

      <Section marginTop={-20}>
        <p className="nested">
          <span>a.</span>
          <span>Email Address</span>
        </p>
      </Section>

      <Section marginTop={-20}>
        <p className="nested">
          <span>b.</span>
          <span>Company</span>
        </p>
      </Section>

      <Section marginTop={-20}>
        <p className="nested">
          <span>c.</span>
          <span>Position</span>
        </p>
      </Section>

      <Section marginTop={-20}>
        <p className="nested">
          <span>d.</span>
          <span>Phone number</span>
        </p>
      </Section>

      <Section marginTop={-20}>
        <p>
          <span>3.</span>
          <span>Retention Period</span>
        </p>
      </Section>

      <Section marginTop={-20}>
        <p className="nested">
          <span>a.</span>
          <span>To be destroyed immediately after account closure or withdrawal of consent to the Terms of Service</span>
        </p>
      </Section>

      <Section marginTop={-20}>
        <p>
          <span>4.</span>
          <span>Refusal</span>
        </p>
      </Section>

      <Section marginTop={-20}>
        <p className="nested">
          <span>a.</span>
          <span>You can still sign up as a member without providing consent, but marketing-related benefits and information will not be offered</span>
        </p>
      </Section>
    </>
  );
};

export default MarketingSection;