import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { colors } from '../../styles/color';

const ContentWrapper = styled.div`
  padding: 8rem 38rem;
  margin-bottom: -60px;
`;

const Introduction = styled.p`
  color: ${colors.neutral[900]};
  font-size: 14px;
  line-height: 21px;
  margin-bottom: -100px;
`;

const MoveTopBtn = styled.div`
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 100;
  cursor: pointer;
  transition: opacity 0.3s ease;

  img {
    width: 40px;
    height: 40px;
    filter: brightness(100%);
    transition: filter 0.3s ease;
  }

  &:hover {
    img {
      filter: brightness(70%);
    }
  }
`;

interface PrivacyTermContentProps {
  showTopButton: boolean;
  onScrollToTop: () => void;
  children: ReactNode;
}

const PrivacyTermContent = ({ showTopButton, onScrollToTop, children }: PrivacyTermContentProps) => {
  return (
    <ContentWrapper id="terms-content">
      <Introduction>
        The purpose of these terms and conditions is to define the terms and conditions of use, procedures, and the
        rights, obligations, and responsibilities of users and the company regarding the use of Celevu, a celebrity
        image usage contract brokerage platform operated by Aligo AI Inc. (hereinafter referred to as "Aligo").
      </Introduction>

      {children}

      {showTopButton && (
        <MoveTopBtn onClick={onScrollToTop}>
          <img src={"/svg/celeblist/move_top.svg"} alt={"move_top"} />
        </MoveTopBtn>
      )}
    </ContentWrapper>
  );
};

export default PrivacyTermContent;