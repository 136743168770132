import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../styles/color';
import TermDownload from '../TermDownload';

const NavigationWrapper = styled.div`
  display: flex;
  width: 1440px;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin: auto;
  flex-wrap: wrap;
`;

const Nav = styled.nav`
  display: flex;
  width: 638px;
  align-items: center;
  gap: 20px;
  flex-shrink: 0;
`;

const NavItem = styled.button<{ isActive?: boolean }>`
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  gap: 8px;
  color: ${props => props.isActive ? colors.neutral['50'] : colors.neutral['900']};
  background: ${props => props.isActive ? colors.neutral['900'] : 'transparent'};
  border-radius: ${props => props.isActive ? '2px' : '0'};
  border: none;
  cursor: pointer;
  
  &:hover {
    color: ${props => props.isActive ? colors.neutral['50'] : colors.neutral['500']};
  }
`;

interface NavigationSectionProps {
  activeTab: string;
  onTabClick: (tab: string) => void;
  onDownload: () => void;
}

const NavigationSection = ({ activeTab, onTabClick, onDownload }: NavigationSectionProps) => {
  const tabs = [
    'General Provisions',
    'Services',
    'Data Management and Privacy',
    'Legal matters',
    'Marketing'
  ];

  return (
    <NavigationWrapper>
      <Nav>
        {tabs.map((tab) => (
          <NavItem
            key={tab}
            isActive={activeTab === tab}
            onClick={() => onTabClick(tab)}
          >
            {tab}
          </NavItem>
        ))}
      </Nav>
      <TermDownload onDownload={onDownload} />
    </NavigationWrapper>
  );
};

export default NavigationSection;