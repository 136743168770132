import React from 'react';
import { Section, MainSection, SubSection, StyledLink } from '../CommonStyles';
const GeneralProvisionsSection = () => {
  return (
    <>
      <Section id="general-provisions">
					<MainSection>I. General Provisions</MainSection>
				</Section>

				<Section marginTop={10}>
					<SubSection>1. Effectiveness and Amendment of the Terms</SubSection>
				</Section>

				<Section marginTop={-20}>
					<p>
						<span>1.</span>
						<span>The contents of these terms and conditions are published on the Celevu website or communicated through other means and become effective for all users upon their agreement during registration.</span>
					</p>
				</Section>

				<Section marginTop={-20}>
					<p>
						<span>2.</span>
						<span>The company reserves the right to amend these terms, provided such amendments do not contravene applicable laws. In the event of amendments, the company shall notify users of the changes either within the service or via email at least 15 days prior to the effective date.</span>
					</p>
				</Section>

				<Section marginTop={-20}>
					<p>
						<span>3.</span>
						<span>The amended terms shall take effect immediately upon announcement. If a user does not raise an objection within seven days of the notification, they shall be deemed to have consented to the amended terms. Users who do not agree to the amended terms may proceed with account deletion.</span>
					</p>
				</Section>

				<Section marginTop={10}>
					<SubSection>2. Definitions</SubSection>
				</Section>

				<Section marginTop={-20}>
					<p>The terms used in these terms and conditions are defined as follows:</p>
				</Section>

				<Section marginTop={-20}>
					<p>
						<span>1.</span>
						<span>Service: Refers to all advertising contract brokerage services provided by the company through <StyledLink
							href="https://www.celevu.ai">www.celevu.ai</StyledLink></span>
					</p>
				</Section>

				<Section marginTop={-20}>
					<p>
						<span>2.</span>
						<span>User: Refers to a person who has entered into a service use contract with the company and registered as a user.</span>
					</p>
				</Section>

				<Section marginTop={-20}>
					<p className="nested">
						<span>a.</span>
						<span>Types:</span>
					</p>
				</Section>

				<Section marginTop={-20}>  {/* 20px에서 12px로 변경 */}
					<p className="nested roman">
						<span>i.</span>
						<span>Advertiser: Includes advertising agencies and advertisers.</span>
					</p>
				</Section>

				<Section marginTop={-20}>  {/* 20px에서 12px로 변경 */}
					<p className="nested roman">
						<span>ii.</span>
						<span>Agency: Includes model and celebrity agencies or management companies representing celebrities.</span>
					</p>
				</Section>

				<Section marginTop={-20}>  {/* 20px에서 12px로 변경 */}
					<p className="nested roman">
						<span>iii.</span>
						<span>Scouter: Refers to an individual who registers a celebrity on this service.</span>
					</p>
				</Section>

				<Section marginTop={-20}>  {/* 0px에서 12px로 변경 */}
					<p className="nested roman">
						<span>iv.</span>
						<span>Users may select their type during registration and must submit necessary documents, such as a business registration certificate or bank account details, to verify their identity before generating advertising contracts.</span>
					</p>
				</Section>
    </>
  );
};

export default GeneralProvisionsSection;