import React from 'react';
import { Section, MainSection, SubSection } from '../CommonStyles';

const ServiceSection = () => {
  return (
    <>
     <Section id="services" marginTop={40}>
					<MainSection>II. Services</MainSection>
				</Section>

				<Section marginTop={10}>
					<SubSection>3. Registration</SubSection>
				</Section>

				<Section marginTop={-20}>
					<p>All users must provide required information on the registration page and agree to the terms and privacy
						policy to register for Celevu.</p>
				</Section>

				<Section marginTop={-20}>
					<div>
						<span>1.</span>
						<span>Required Information:</span>
					</div>
				</Section>
				<Section marginTop={-40}>
					<p className="nested">
						<span>a.</span>
						<span>Company name</span>
					</p>
				</Section>

				<Section marginTop={-20}>
					<p className="nested">
						<span>b.</span>
						<span>Job title</span>
					</p>
				</Section>

				<Section marginTop={-20}>
					<p className="nested">
						<span>c.</span>
						<span>Company email address</span>
					</p>
				</Section>

				<Section marginTop={-20}>
					<p>
						<span>2.</span>
						<span>Account Approval and Registration: The company will review the provided information and may request additional documents before approving the account.</span>
					</p>
				</Section>

				<Section marginTop={-20}>
					<p>
						<span>3.</span>
						<span>Registration Documents:</span>
					</p>
				</Section>

				<Section marginTop={-20}>
					<p className="nested">
						<span>a.</span>
						<span>Advertiser: Copy of business registration certificate</span>
					</p>
				</Section>

				<Section marginTop={-20}>
					<p className="nested">
						<span>b.</span>
						<span>Agency:</span>
					</p>
				</Section>

				<Section marginTop={-20}>
					<p className="nested roman">
						<span>i.</span>
						<span>Business registration certificate, bank account copy</span>
					</p>
				</Section>

				<Section marginTop={-20}>
					<p className="nested roman">
						<span>ii.</span>
						<span>(Optional) Celebrity's name, ID number, and address *Please refer to Article 6 for more information.</span>
					</p>
				</Section>

				<Section marginTop={-20}>
					<p>
						<span>4.</span>
						<span>Users must ensure that the provided information is accurate and are responsible for managing their account information and security.</span>
					</p>
				</Section>

				<Section marginTop={10}>
					<SubSection>4. Provided Services</SubSection>
				</Section>

				<Section marginTop={-20}>
					<p>The company provides following services:</p>
				</Section>

				<Section marginTop={-20}>
					<p>
						<span>1.</span>
						<span>Online Portrait Rights Usage Contracts:</span>
					</p>
				</Section>

				<Section marginTop={-20}>
					<p className="nested">
						<span>a.</span>
						<span>Overview:</span>
					</p>
				</Section>

				<Section marginTop={-20}>
					<p className="nested roman">
						<span>i.</span>
						<span>Advertisers can use celebrity portraits for various online media.</span>
					</p>
				</Section>

				<Section marginTop={-20}>
					<p className="nested roman">
						<span>ii.</span>
						<span>Agencies must provide celebrity images and agree to their usage according to the contract.</span>
					</p>
				</Section>

				<Section marginTop={-20}>
					<p className="nested roman">
						<span>iii.</span>
						<span>Advertisers may edit and modify the provided images but must adhere to the detailed contract terms.</span>
					</p>
				</Section>

				  <Section marginTop={-20}>
					<p className="nested roman">
						<span>iv.</span>
						<span>The detailed contract terms (e.g., portrait rights usage period, media, advertising region) are finalized through mutual agreement among the advertiser, agency, and celebrity within the Celevu platform.</span>
					</p>
				</Section>

				<Section marginTop={-20}>
					<p className="nested">
						<span>b.</span>
						<span>Post-Management: The advertiser shall make every effort to ensure that the celebrity's image is not inappropriately used after the expiration of the portrait rights usage contract period and shall take all reasonable measures to this end.</span>
					</p>
				</Section>

				<Section marginTop={-20}>
					<p className="nested">
						<span>c.</span>
						<span>Media Usage:</span>
					</p>
				</Section>

				<Section marginTop={-20}>
					<p className="nested roman">
						<span>i.</span>
						<span>Online pages (URL)</span>
					</p>
				</Section>

				<Section marginTop={-20}>
					<p className="nested roman">
						<span>ii.</span>
						<span>Online advertisements (e.g., Instagram, Facebook)</span>
					</p>
				</Section>

				<Section marginTop={-20}>
					<p className="nested roman">
						<span>iii.</span>
						<span>Emails or newsletters</span>
					</p>
				</Section>

				<Section marginTop={-20}>
					<p className="nested roman">
						<span>iv.</span>
						<span>Other media usages may be arranged through additional consultations.</span>
					</p>
				</Section>

				<Section marginTop={-20}>
					<p>
						<span>2.</span>
						<span>Social Media Content Posting Contracts:</span>
					</p>
				</Section>

				<Section marginTop={-20}>
					<p className="nested">
						<span>a.</span>
						<span>Overview:</span>
					</p>
				</Section>

				<Section marginTop={-20}>
					<p className="nested roman">
						<span>i.</span>
						<span>Celebrities will produce and post content related to the advertiser's product or brand on their own social media accounts.</span>
					</p>
				</Section>

				<Section marginTop={-20}>
					<p className="nested roman">
						<span>ii.</span>
						<span>After posting, the link to the post must be registered on the Celevu contract page.</span>
					</p>
				</Section>

				<Section marginTop={-20}>
					<p className="nested roman">
						<span>iii.</span>
						<span>The detailed contract terms (e.g., SNS account name, posting frequency, posting duration) are finalized through mutual agreement between the advertiser and the agency within the Celevu platform.</span>
					</p>
				</Section>

				<Section marginTop={-20}>
					<p className="nested">
						<span>b.</span>
						<span>Media Usage:</span>
					</p>
				</Section>

				<Section marginTop={-20}>
					<p className="nested roman">
						<span>i.</span>
						<span>Instagram</span>
					</p>
				</Section>

				<Section marginTop={-20}>
					<p className="nested roman">
						<span>ii.</span>
						<span>Youtube</span>
					</p>
				</Section>

				<Section marginTop={-20}>
					<p className="nested roman">
						<span>iii.</span>
						<span>If the advertiser requests the use of other media, it can be arranged through additional consultations on the platform.</span>
					</p>
				</Section>

				<Section marginTop={-20}>
					<p>
						<span>3.</span>
						<span>Additional Services:</span>
					</p>
				</Section>

				<Section marginTop={-20}>
					<p className="nested">
						<span>a.</span>
						<span>Event Invitations</span>
					</p>
				</Section>

				<Section marginTop={-20}>
					<p className="nested">
						<span>b.</span>
						<span>Brand model or ambassador contracts</span>
					</p>
				</Section>

				<Section marginTop={-20}>
					<p>All services provided can be carried out in multiples, and the related contracts are concluded exclusively
						within the Celevu platform.</p>
				</Section>

				<Section marginTop={10}>
					<SubSection>5. Contract Execution</SubSection>
				</Section>

				<Section marginTop={-20}>
					<p>The advertiser, agency, and celebrity shall enter into a contract by providing other required legal
						information necessary for drafting the advertising contract and signing electronically within the
						service.</p>
				</Section>

				<Section marginTop={10}>
					<SubSection>6. Payment and Settlement Procedures</SubSection>
				</Section>

				<Section marginTop={-20}>
					<p>The timing of contract fee payment and the procedures for settlement shall vary depending on the type of
						contracted service. The detailed provisions are as follows.</p>
				</Section>

				<Section marginTop={-20}>
					<p>
						<span>1.</span>
						<span>Online Portrait Rights Usage Contracts:</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p className="nested">
						<span>a.</span>
						<span>Advertiser: The advertiser shall remit 108% of the contract amount to Aligo within two weeks from the date of contract execution (the date on which the celebrity accepts the contract) following the submission of the contract.</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p className="nested">
						<span>b.</span>
						<span>Agency.</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p className="nested roman">
						<span>i.</span>
						<span>The fee shall be paid within two weeks from the date on which the celebrity confirms the finalized advertisement after its review.</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p className="nested roman">
						<span>ii.</span>
						<span>The fee shall be directly paid to the agency as previously agreed, and once Aligo remits the fee to the agency, it shall be deemed to have fulfilled its obligation to pay the portrait rights fee to both the agency and the celebrity.</span>
					</p>
				</Section>

				<Section marginTop={-20}>
					<p className="nested roman">
						<span>iii.</span>
						<span>The fee shall be calculated by deducting an 8% service fee and any applicable remittance or other fees from Aligo’s net revenue, with the remaining amount paid to the agency.</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p>
						<span>2.</span>
						<span>Social Media Content Posting Contracts:</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p className="nested">
						<span>a.</span>
						<span>Advertiser: The advertiser shall remit 108% of the contract amount to Aligo within two weeks from the date of contract execution (the date on which the celebrity accepts the contract) following the submission of the contract.</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p className="nested">
						<span>b.</span>
						<span>Agency.</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p className="nested roman">
						<span>i.</span>
						<span>Once the celebrity posts the finalized content on their personal social media account and uploads the corresponding link to the platform, the fee shall be paid within two weeks of the posting date after the advertiser verifies the post.</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p className="nested roman">
						<span>ii.</span>
						<span>The fee shall be directly paid to the agency as agreed in advance. Once Aligo remits the fee to the agency, it shall be deemed to have fulfilled its obligation to pay the performance fee to both the agency and the celebrity.</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p className="nested roman">
						<span>iii.</span>
						<span>The fee shall be calculated by deducting an 8% service fee and any applicable remittance or other fees from Aligo’s net revenue, with the remaining amount paid to the agency.</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p>
						<span>3.</span>
						<span>Payment Currency and Method:</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p className="nested">
						<span>a.</span>
						<span>The advertiser may determine the currency and payment method at the time of remitting the contract fee. The payable amount shall be calculated based on the exchange rate applicable on the payment date.</span>
					</p>
				</Section>
				<Section marginTop={10}>
					<SubSection>7. Restriction on Assignment of Rights and Obligations</SubSection>
				</Section>
				<Section marginTop={-20}>
					<p>None of the parties to this contract may transfer, delegate, or otherwise dispose of their rights and
						obligations under these terms and the contract to any third party without prior written consent from all
						parties involved</p>
				</Section>

				<Section marginTop={10}>
					<SubSection>8. Termination of Contract and Refund</SubSection>
				</Section>
				<Section marginTop={-20}>
					<p>
						<span>1.</span>
						<span>Termination and Cancellation of Contract</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p className="nested">
						<span>a.</span>
						<span>If any of the following circumstances occur to one of the parties to this contract, the other party may unilaterally terminate or cancel the entirety or a part of this contract:</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p className="nested roman">
						<span>i.</span>
						<span>If one party violates the terms stipulated in this contract and fails to remedy the breach within 15 business days of receiving a written demand for rectification from the other party.</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p className="nested roman">
						<span>ii.</span>
						<span>If one party becomes unable to fulfill the obligations stipulated in this contract due to the initiation of rehabilitation procedures, bankruptcy, dissolution, liquidation, closure of business, or other similar circumstances.</span>
					</p>
				</Section>
				  <Section marginTop={-20}>
					<p className="nested roman">
						<span>iii.</span>
						<span>If one party is unable to fulfill the obligations stipulated in this contract due to the attachment, provisional seizure, auction, or similar action taken against its primary assets.</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p className="nested roman">
						<span>iiii.</span>
						<span>If performance of mutual contractual obligations or the continuation of business becomes impossible due to force majeure events beyond control, such as war, civil commotion, earthquakes, typhoons, floods, storms, pandemics, or other extreme weather or emergency disaster situations.</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p className="nested roman">
						<span>iiiii.</span>
						<span>If the advertiser or the celebrity, during the contract period, violates criminal laws or becomes involved in socially reprehensible incidents that damage the reputation or image of the advertiser or the celebrity, the contract may be terminated by mutual agreement.</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p>
						<span>2.</span>
						<span>Refund Procedure</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p className="nested">
						<span>a.</span>
						<span>Depending on the stage of contract termination, the advertiser may receive the following refunds from Aligo:</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p className="nested roman">
						<span>i.</span>
						<span>Before final approval of the advertisement: The advertiser is entitled to a 100% refund of the contract amount paid.</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p className="nested roman">
						<span>ii.</span>
						<span>After final approval of the advertisement: No refunds will be issued once the advertisement has been finalized and approved by the celebrity and the agency.</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p className="nested">
						<span>b.</span>
						<span>The refund amount will be processed within 30 days from the date of the contract termination notice. Upon completion of the refund, a separate notification will be sent to the advertiser.</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p className="nested">
						<span>c.</span>
						<span>Non-Refundable Circumstances</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p className="nested roman">
						<span>i.</span>
						<span>If the advertisement has already been published</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p className="nested roman">
						<span>ii.</span>
						<span>If the contract explicitly specifies non-refundable conditions</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p className="nested roman">
						<span>iii.</span>
						<span>If refunds are restricted under Celevu's policies</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p className="nested">
						<span>d.</span>
						<span>Refund Fees</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p className="nested roman">
						<span>i.</span>
						<span>Certain fees may apply depending on the payment method used for the refund, and these fees will be deducted from the refund amount.</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p className="nested">
						<span>e.</span>
						<span>Refund Currency and Exchange Rate</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p className="nested roman">
						<span>i.</span>
						<span>The refund amount will be issued in the currency and payment method initially selected at the time of the contract fee payment. The refund amount will be calculated based on the exchange rate on the payment date, after deducting any applicable fees.</span>
					</p>
				</Section>
				<Section marginTop={10}>
					<SubSection>9. Account Deletion</SubSection>
				</Section>
				<Section marginTop={-20}>
					<p>
						<span>1.</span>
						<span>Procedure</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p className="nested">
						<span>a.</span>
						<span>A user may request to delete their account from the Celevu service at any time. To initiate account deletion, the user may submit a withdrawal request directly through the "Account Deletion" menu within the platform. Upon receipt of the request, Celevu will promptly process the deletion.</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p>
						<span>2.</span>
						<span>Effects</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p className="nested">
						<span>a.</span>
						<span>Account Deletion: Upon completion of the deletion process, the user’s account and related information will be deleted immediately. However, information that must be retained in accordance with applicable laws will be securely stored separately.</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p className="nested">
						<span>b.</span>
						<span>Termination of Service Use: After deletion, the user will no longer have access to the Celevu platform, and service records prior to deletion cannot be restored.</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p className="nested">
						<span>c.</span>
						<span>Contracts and Payment Records: Rights and obligations related to contracts and payment records established prior to deletion will remain valid even after deletion. These matters will be governed by the relevant contracts or terms and conditions.</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p>
						<span>3.</span>
						<span>Rejoining</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p className="nested">
						<span>a.</span>
						<span>Users may rejoin Celevu at any time. However, previous accounts and data will not be restored upon rejoining. Users wishing to rejoin must complete the registration process as new users.</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p>
						<span>4.</span>
						<span>Data Retention</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p className="nested">
						<span>a.</span>
						<span>In accordance with applicable laws, Celevu may retain transaction records and payment details for a designated period following account deletion. During this retention period, the information will only be used for legal purposes, and it will be permanently deleted once the retention period expires.</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p>
						<span>5.</span>
						<span>Important Considerations</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p className="nested">
						<span>a.</span>
						<span>Account recovery is not possible after deletion, and services or benefits purchased or provided prior to deletion will no longer be accessible.</span>
					</p>
				</Section>
				<Section marginTop={-20}>
					<p className="nested">
						<span>b.</span>
						<span>Users remain responsible for any ongoing contracts or payment obligations at the time of deletion, in accordance with the relevant contract and payment terms.</span>
					</p>
				</Section>
    </>
  );
};

export default ServiceSection;